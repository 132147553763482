import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of, take } from 'rxjs';

import { MapSettingsLoader } from './maps.module';

@Injectable()
export class MapsService {
  apiLoaded$ = new BehaviorSubject(false);
  mapsKey: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly settings: MapSettingsLoader,
  ) {
    this.mapsKey = this.settings.mapsKey;
    this.loadMaps();
  }

  private loadMaps() {
    this.httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.mapsKey}&libraries=places`, 'callback')
      .pipe(
        take(1),
        map(() => true),
        catchError((e) => {
          console.error('Error loading Google Maps API', e);
          return of(false);
        }),
      )
      .subscribe((apiLoaded) => {
        this.apiLoaded$.next(apiLoaded);
      });
  }
}
