<div *ngIf="apiLoaded$ | async" class="h-full w-full relative">
  <google-map
    (mapInitialized)="onMapInitialized()"
    [center]="center"
    [options]="options"
    [zoom]="zoom"
    height="100%"
    width="100%"
    class="h-full w-full"
  >
    <ng-container *ngFor="let marker of markers; trackBy: trackByFn">
      <map-marker
        #mapMarker="mapMarker"
        [position]="{ lat: marker.lat, lng: marker.lng }"
        [icon]="marker.icon || ''"
        [options]="markerOptions"
        (mapClick)="openInfoWindow(mapMarker, marker)"
      ></map-marker>
      <map-circle
        *ngIf="marker.radius"
        [radius]="marker.radius"
        [center]="{ lat: marker.lat, lng: marker.lng }"
        [options]="{
          fillColor: '#FF0000',
          strokeColor: '#FF0000'
        }"
      ></map-circle>
    </ng-container>

    <map-info-window *ngIf="showMarkerInfo">{{ infoWindowText }}</map-info-window>

    <map-circle
      *ngFor="let circle of circles; let i = index; trackBy: trackByFn"
      [center]="circle.center"
      [radius]="circle.radius"
      [options]="circleOptions"
      (centerChanged)="onCenterChange(circle, i)"
      (radiusChanged)="onRadiusChange(circle, i)"
    ></map-circle>
  </google-map>

  <!-- slot to render content on top of the map -->
  <ng-content></ng-content>
</div>
